/***** TEAMS STYLES *****/
.teams-container {
  background: #d1f8f1;
  padding: 72px 16px;
  display: flex;
  justify-content: center;
  position: relative;
  .container {
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
  .background-text {
    font-family: ArchivoCondensed;
    text-transform: uppercase;
    position: absolute;
    top: 80px;
    left: -80px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    font-size: 172px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.2);
    @media only screen and (max-width: 600px) {
      left: 0;
    }
  }
}
.teams-container-left {
  max-width: 744px;
  width: 100%;
  @media only screen and (max-width: 1040px) {
    max-width: calc(100% - 296px);
  }
  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
}
.teams-container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  grid-gap: 8px;
  margin-bottom: 8px;
  flex-flow: wrap;
  @media only screen and (max-width: 600px) {
    display: block;
  }
}
.teams-container-header.no-teams {
  justify-content: unset;
}
/*** Search and Filter Styles ***/
.teams-search-input {
  border-radius: 100px;
  border: 1px solid #b5b6ba;
  padding: 0 16px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 520px;
  min-width: 200px;
  transition: border 300ms linear;
  position: relative;
  flex: 1;
  // .search-input-focused {
  //   border: 1px solid $accent-color;
  // }
  i {
    color: #828282;
  }
  input {
    background: transparent;
    border: none;
    font-size: 14px;
    width: calc(100% - 16px);
  }
  input:focus {
    outline: none;
    border: none;
  }
}
.teams-sort-by-select {
  border: none;
  background: transparent;
  font-size: 14px;
  color: $primary-color;
  width: 130px;
}
/*** Team Cards Styles ***/
.teams-grid {
  display: grid;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
.team-card {
  background: #fff;
  padding: 16px;
  border-radius: 3px;
  width: 100%;
  max-width: 360px;
}
.team-percentage-bar {
  height: 16px;
}
/*** Donor List Styles ***/
.donor-list {
  width: 360px;
  max-width: 100%;
  max-height: 544px;
  height: 100%;
  padding: 16px 24px 24px;
  margin-left: 16px;
  border-radius: 3px;
  background: #fff;
  @media only screen and (max-width: 1040px) {
    width: 280px;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-left: 0;
    margin-top: 32px;
  }
  .donor-list-items {
    overflow: auto;
    max-height: 336px;
    margin-top: 16px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .donor-list-item {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 16px;
    margin-bottom: 16px;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
.donor-list-filters .search-input {
  border: 1px solid #b5b6ba;
  height: 40px;
  margin: 8px 0;
}
.donor-list-filters .select-container {
  display: flex;
  align-items: center;
  p {
    white-space: nowrap;
    margin-right: 16px;
    font-family: Inter;
  }
  select {
    border: none;
    background: transparent;
    font-size: 14px;
    color: $primary-color;
    width: 100px;
    height: auto;
    &:focus {
      outline: none;
    }
  }
}

/*** Team Donor List Styles ***/
.team-donor-list {
  width: 100%;
  padding: 24px 32px;
  border-radius: 8px;
  background: #fff;
  .donor-list-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 64px;
    margin-top: 32px;
    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }
  .donor-list-item {
    border-bottom: 1px solid #b5b6ba;
    padding: 16px 0;
  }
}

/*** Create Team Styles ***/
.create-team-btn {
  @extend .btn, .btn-rounded;
  width: 190px;
  @media only screen and (max-width: 600px) {
    margin-bottom: 8px;
  }
}
/*** Create Team Modal Styles ***/
.team-modal {
  @extend .modal-container;
  padding: 60px 40px;
  @media only screen and (max-width: 600px) {
    max-height: 96vh;
    max-width: 94vw;
  }
}
.team-modal-title {
  margin-bottom: 48px;
  font-size: 40px;
  font-weight: bold;
  color: $primary-color;
  @media only screen and (max-width: 600px) {
    font-size: 32px;
  }
}
.team-modal-inputs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 48px;
  align-items: start;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.team-modal-input-container {
  display: flex;
  align-items: center;
  height: 100%;
  > div {
     width: 100%;
     position: relative;
  }
  label {
    width: 160px;
    min-width: 160px;
    opacity: 0.6;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1.2;
  }
  .error-text {
    position: absolute;
    left: 0px;
    bottom: -16px;
  }
  .sub-text {
    position: absolute;
    left: 0px;
    bottom: -18px;
    opacity: 0.6;
    color: rgba(0, 0, 0, 0.87);
    font-size: 11px;
    line-height: 1.2;
    height: 16px;
    margin-top: 4px;
  }
  .goal-tickets-text {
     width: unset;
     min-width: unset;
     margin-left: 16px;
  }
  @media only screen and (max-width: 600px) {
    display: block;
  }
}
.team-modal-footer {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  .btn-cancel {
    @extend .btn, .btn-text;
    width: 128px;
    color: #3673A6;
  }
  .btn-save {
    @extend .btn, .btn-rounded;
    letter-spacing: 2px;
  }
  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
}
.team-modal-error-message {
  @extend .error-text;
  text-align: right;
  margin-top: 8px;
}
/*** Team Created Modal Styles ***/
.team-created-modal-portal .ReactModal__Overlay {
  justify-content: end;
  align-items: end;
  padding: 48px;
  background: none !important;
  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
}
.team-created-modal {
  @extend .modal-container;
  padding: 40px;
  width: 456px;
  position: relative;
  .btn-text {
    color: #2774ae;
  }
  @media only screen and (max-width: 600px) {
    width: unset;
    min-width: 94vw;
  }
}
.team-created-close-button {
  @extend .accent-text;
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  font-size: 20px;
  cursor: pointer;
  :hover {
    transform: scale(1.05);
  }
}
.team-created-title {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
  color: $primary-color;
}
.team-created-message {
  margin-bottom: 20px;
  color: #000;
  opacity: 0.6;
  font-size: 16px;
  line-height: 1.44;
}
.team-created-url {
  display: flex;
  margin-bottom: 26px;
  font-size: 16px;
  color: #2774ae;
  i {
    margin-right: 8px;
  }
}
